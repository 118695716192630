import * as React from 'react'
import Header from '../components/header'
import { StaticImage } from 'gatsby-plugin-image'

import Seo from '../components/seo'
import { Link } from 'gatsby'
import Logo from '../images/logo.svg'

import '../styles/style.scss'

const Contact = () => (
  <>
    <Seo title="İletişim" />
    <Header />
    <div className="container">
      <div className="about-hero">
        <StaticImage
          src="../images/logo.svg"
          alt="BM Creative Works"
          className="menu-logo"
        />
      </div>
      <div className="contact-head">
        <h2>bizimle iletişime geç</h2>
        <ul>
          <li>
            <a href="mailto:hello@bmcreative.works">mail</a>
          </li>
          <li>
            <a href="https://bmcw.link/i" target="_blank">
              instagram
            </a>
          </li>
          <li>
            <a href="https://bmcw.link/fb" target="_blank">
              facebook
            </a>
          </li>
          <li>
            <a href="https://bmcw.link/li" target="_blank">
              linkedin
            </a>
          </li>
        </ul>
        <div className="alt">
          <Link to="/">©2022 BMCW</Link>
          <img src={Logo} alt="BM Creative Works Logo" />
          <Link to="/legal/">cookies</Link>
        </div>
      </div>
    </div>
  </>
)
export default Contact
